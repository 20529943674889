.work-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
}



.project-heading {
    text-align: center;
    padding: 2rem 0;
}

.project-container {
    max-width: 1140px; 
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
    padding-bottom: 4rem;
}

.project-card {
    
    padding: 0.8rem;
    flex: 0 1 calc(44.333% - 40px);
    flex-direction: column;
    perspective: 1000px;
    position: relative;
    cursor: pointer;
    transition: height 0.6s, transform 0.6s;
    box-sizing: border-box;
    align-items: center;
    max-width: 100%;
    max-height: 50%;
    /* the below is for reveal content */
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

.project-card.visible {
    opacity: 1;
    transform: translateY(0);
  }
  
  .project-card.hidden {
    opacity: 0;
    transform: translateY(20px);
  }

.project-card .thefront, .project-card .theback {
    width: 100%;
    backface-visibility: hidden;
    transition: transform 0.6s;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:center;
}

.project-card .thefront {
    transform: rotateY(0deg);
}

.project-card .theback {
    transform: rotateY(180deg);
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
}

.project-card.flipped .thefront {
    transform: rotateY(-180deg);
}

.project-card.flipped .theback {
    z-index: 2;
    transform: rotateY(0deg);
}

.project-card img {
    width: 100%;
    height: auto;
    border-radius: 10px;
    
    margin-bottom: 10px;
    object-fit: cover;
}
.project-card img:hover {
    transform: scale(1.1);
    box-shadow: 0 5px 20px rgba(231, 243, 57, 0.5);
  }

.project-title {
    color: #f0e7e7;
    padding: 1rem 0;
    text-align: center;
    font-size: 1.2rem;
}

.pro-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
}

.pro-details p {
    margin: 20px;
    
    font-size: 1rem;
    text-align: justify;
    width: 100%;

}

.pro-btns {
    
    display: flex;
    justify-content: space-between;
    margin-top: auto;
    width: 100%;
}

.pro-btns .btn {
    padding: 0.5rem 1rem;
    border-radius: 5px;

    text-decoration: none;
    flex: 1;
    margin: 0 5px;
    text-align: center;
}

/* Media Query for smaller screens */
@media screen and (max-width: 740px) {
    .project-container {
        max-width: 90%;
        margin: auto;
        grid-template-columns: 1fr; /* Stack cards vertically */
        padding-bottom: 2rem;
    }

    .project-card {
        flex: 0 1 auto; /* Full width for smaller screens, one card per row */
        margin-bottom: 40px; /* Adds space between stacked cards */
        width: 100%;
    }

    .project-card .thefront, .project-card .theback {
        flex-direction: column;
        justify-content: flex-start; /* Align content to the start */
        align-items: center; /* Center items */
        padding: 0.8rem;
    }

    .project-title {
        text-align: center; /* Center the title */
        width: 100%;
    }

    .pro-details {
        padding: 0.01rem;
    }
    .pro-details p {
        margin: 20px;
        
        font-size: 0.8rem;
        text-align: justify;
        width: 90%;
    
    }
    .project-card img {
        width: 100%;
        height: auto;
    }

    .pro-btns {
        display: flex;
        justify-content: space-between; /* Ensure space between buttons */
        width: 100%;
    }

    .pro-btns .btn {
        flex: 1;
        margin: 0 5px; /* Add margin between buttons */
        text-align: center;
    }
}

/* Media Query for medium screens */
@media screen and (max-width: 810px) {
    .project-container {
        max-width: 90%;
        margin: auto;
        grid-template-columns: 1fr; /* Stack cards vertically */
    }

    .project-card {
        flex: 0 1 auto; /* Full width for medium screens, one card per row */
        margin-bottom: 60px; /* Adds space between stacked cards */
        width: 100%;
    }

    .project-card .thefront, .project-card .theback {
        flex-direction: column;
        justify-content: flex-start; /* Align content to the start */
        align-items: center; /* Align items to the start */
        padding: 0.8rem;
    }

    .pro-details {
        padding: 0.01rem;
    }

    .project-card img {
        width: 100%;
        height: auto;
    }

    .pro-btns {
        display: flex;
        justify-content: space-between; /* Ensure space between buttons */
        width: 100%;
    }

    .pro-btns .btn {
        flex: 1;
        margin: 0 5px; /* Add margin between buttons */
        text-align: center;
    }
}
