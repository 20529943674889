.header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    position: fixed;
    width: 100%;
    height: 90px;
    z-index: 10;
}

.header-bg{
    background-color: rgba(0,0,0,0.85);
    transition: 0.5s;
}
.nav-menu{
    display: flex;
}

.nav-menu li{
    padding: 0 1rem;
    
}

.nav-menu li a{
    font-size: 1.2rem;
    font-weight: 500;
}

.nav-menu li a:hover{
    color: yellow;
}
/* this will not display hamburger, see the below code */
.hamburger {
    display: none;
    cursor: pointer;
} 
.hamburger svg {
    color: white;
    transition: transform 0.1s ease; 
}

.hamburger:hover svg {
    color: yellow;
    transform: scale(1.3);
}


@media screen and (max-width: 1040px) {
    .nav-menu{
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 50%;
        height: 100vh;
        background: rgba(0, 0, 0, 0.9);
        position: absolute;
        top: 0;
        right: -100%;
        z-index: -3;
        transition: 0.4s;
    }
    .nav-menu.active{
        right: 0;
    }
    .nav-menu li {
        padding: 1rem 0;
        
    }
    .navmenu li a {
        font-size: 2rem;
    }
    .hamburger{
        display: initial;
    }
    
}