label{
    color:azure;
    margin-bottom: 0.5rem;
}

form{
    display: flex;
    flex-direction: column;
    padding: 4rem 1rem;
    margin: auto;
    max-width: 600px;
}


form input, form textarea{
    margin-bottom: 1rem;
    padding: 10px 18px;
    font-size: 1.2rem;
    background-color: rgba(255,255,255,0.1);
    border-color:rgba(255,255,255,0.1);
    color: white;
}
.error {
    color: red;
    font-size: 0.8rem;
    margin-top: 0rem;
    margin-bottom: 0.3rem;
  }
  .popup {
    position: fixed;
    top: 20px;
    right: 20px;
    padding: 10px 20px;
    background-color: rgb(234, 236, 105);
    color: rgb(15, 14, 14);
    border-radius: 5px;
    z-index: 1000;
  }
  