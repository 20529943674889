  .about .left, .about .right, .about .bottom {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.6s ease-out, transform 0.6s ease-out;
  }
  
  .about .left.visible, .about .right.visible, .about .bottom.visible {
    opacity: 1;
    transform: translateY(0);
  }

  

  .about {
    width: 100%;
    margin-top: 5rem;
    margin-bottom: 5rem;
    display: flex;
    justify-content: space-between; /* Ensure columns are side by side */
    align-items: flex-start; /* Align items at the top */
    flex-wrap: wrap;
    height: 100%;
}

  .left, .right {
    flex: 1;
    margin: 0 1rem;
    min-width: 300px;
    
  
  }

  .left h1{  
    margin-top: 1rem;
    margin-bottom: 1rem;
    text-align: center;
    
  }

  .right h1 {  
    margin-top: 1rem;
    margin-bottom: 1rem;
    text-align: center;
   
  }
  .bio-text {
    margin-bottom: 1rem;
    margin-top: 4rem;
  }
  
  .skills {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: center;
    align-items: center;
  }
  
  .skill {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100px;
    
  }
  .skill:hover {
    background: rgba(255,255,255,0.2);
    transition: 0.03s;
    border-radius: 5px;
    box-shadow: 0 5px 20px rgba(238, 226, 55, 0.2);
  }
  
  .skill svg {
    margin-top: 2rem;
    width: 50px;
    height: 50px;
  }
  
  .skill p {
    margin-top: 0.5rem;
    font-size: 1rem;
    text-align: center;
  }
  .btn-container {
    display: flex;
    justify-content: space-between;
    margin-top: auto;
  }

  .btn-container .btn{
    padding: 0.5rem 1rem;
  }

  .bottom {
    display: flex;
    width: 100%;
    justify-content: center;
    overflow-x: auto;
    padding: 20px;
    gap: 20px;
    flex-wrap: wrap;
  
  }
  
  .bottom .certificate {
    max-width: 300px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* box-shadow: 0 5px 20px rgba(231, 243, 57, 0.5); */
    transition: transform 0.3s ease-in-out;
    position: relative;
    margin: 10px;
    overflow: hidden;
  }
  
  .bottom .certificate:hover {
    transform: scale(1.1);
    box-shadow: 0 5px 20px rgba(231, 243, 57, 0.5);
  }
  
  .bottom .certificate .jpeg {
    width: 100%;
    height: auto;
    border-radius: 5px;
    box-shadow: 0 5px 20px rgba(209, 200, 76, 0.2);
    margin-bottom: 10px;
  }
  



   
  @media screen and (max-width: 740px) {
    .about {
      flex-direction: column;
    }
  
    .left, .right {
      margin: 1rem 0;
      min-width: 100%;
    }
  
    .skills {
      justify-content: center;
      align-items: center;
    }
    .skill {
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
    .bottom {
      flex-direction: column; /* Stack certificates vertically on small screens */
      align-items: center; /* Center-align the certificates */
      overflow-x: hidden; /* Remove horizontal scroll on small screens */
     
    }
    
    .bottom .certificate {
      max-width: 90%; /* Reduce width to fit better on small screens */
    }
  }


  