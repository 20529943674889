.hero-img3{
    width: 100%;
    background-color: rgba(0,0,0,0.5);
    height: 100vh;
    position: relative;
    overflow: hidden;
}

.hero-img3::before{
    content: "";
    background: url("../assets/img3.jpg");
    background-size: cover;
    background-position: center top;
    background-attachment: fixed;
    height:100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    background-color: black;
    opacity: 0.7;
    
}
.heading{
    width:100%;
    height:100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.hero-img4 h1{
    font-size: 2.4rem;
}

.hero-img4 p{
    font-size: 1.4rem;
    text-align: center;
}

@media screen and (max-width: 640px) {

    .hero-img3::before {
        background-size: cover; 
        background-position: center center; 
        background-attachment: scroll;
    }
    .hero-img4 h1 {
        font-size: 2rem;
    }
}