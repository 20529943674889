@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');

*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Outfit", sans-serif;
}

body {
  background: black;;
}
/* index.css */
html {
  scroll-behavior: smooth;
}

h1, h4, p, a{
  color: white;
  text-decoration: none;
}

p{
  text-align: justify;

}

ul{
  list-style-type: none;
}

.btn {
  padding: 12px 32px;
  font-size: 1rem;
  text-transform: uppercase;
  background: yellow;
  color: black;
  border: 1px solid white;
  font-weight: 600;
  cursor: pointer;
}

.btn-light{
  background: transparent;
  color: white;
}

.btn:hover {
  background: rgba(255,255,255,0.2);
  color: white;
  transition: 0.03s;
}