.hero-img {
    width: 100%;
    height: 100vh;
    position: relative;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1;
  }
  
  .hero-img::before {
    content: "";
    background: url("../assets/img2.jpg"); /* Add your background image path */
    background-size: cover;
    background-position: center top;
    background-attachment: fixed;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    opacity: 0.5; /* Adjust this to make the background image darker */
    transition: transform 0.3s 

/* .hero-img{
    width: 100%;
    background-color: rgba(0,0,0,0.5);
    height: 100vh;
    position: relative;
    overflow: hidden;

    background-color:rgba(0, 0, 0, 0.5); */
    /* background-color: black; */
    /* opacity: 0.9;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.hero-img::before{
    content: ""; */
    /* background: url("https://media.istockphoto.com/id/1563029018/photo/machine-learning-process-artificial-intelligence-ai-machine-learning-and-deep-data-mining.jpg?s=612x612&w=0&k=20&c=VHsqJN4alroYWMT19h365z7_lj7aiccubTAbVHwtgJc="); */
   
   
    /* background: url("../assets/img2.jpg");
    background-size: cover;
    background-position: center top;
    background-attachment: fixed;
    height:100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1; */


    /* background-color:rgba(0, 0, 0, 0.5);
    /* background-color: black; */
    /* opacity: 0.9; */ 

    
    /* transition: transform 0.3s ease; */
    
}
.heading{
    width:100%;
    height:100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.hero-img h1{
    font-size: 2.4rem;
}

.hero-img p{
    font-size: 1.4rem;
    text-align: center;
}

 @media screen and (max-width: 640px) {
    
    .hero-img::before {
        background-size: cover; 
        background-position: center center; 
        background-attachment: scroll;
    }

    .hero-img h1 {
        font-size: 2rem;
    }

 }

